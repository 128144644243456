<template>
  <div class="my_input">
    <span v-text="name" :class="mySpanClass"></span>
    <el-date-picker
      class="input_tag"
      v-model="dateValue"
      type="datetime"
      prefix-icon="iconfont icon-rili"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      clear-icon="iconfont icon-shanchu"
      value-format="yyyy-MM-dd HH:mm:ss">
      <i class="iconfont icon-shaixuantiaojian-shurukuang write"></i>
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: [String, Date],
      default () {
        return ''
      }
    },
    name: {
      type: [String]
    },
    readonly: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      dateValue: '',
      onFocus: false,
      isShow: false
    }
  },
  computed: {
    mySpanClass () {
      if (this.onFocus || this.dateValue) {
        return 'to_top span_tag'
      } else {
        return 'span_tag'
      }
    }
  },
  watch: {
    value (val) {
      this.setCurrentValue(val)
    }
  },
  mounted () {
    this.setCurrentValue(this.value)
  },
  methods: {
    onEnter () {
      this.$emit('enter')
    },
    handleFocus (event) {
      this.onFocus = true
      this.$emit('on-focus', event)
    },
    handleBlur (event) {
      this.onFocus = false
      this.$emit('on-blur', event)
    },
    setCurrentValue (value) {
      if (value === this.dateValue) return
      this.dateValue = value
    },
    deleteSelect () {
      this.dateValue = ''
      this.$emit('input', '')
    },
    handleChange (value) {
      this.$emit('change', value)
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.my_input {
  float: left;
  width: 180px;
  height: 40px;
  margin: 10px 0 20px 20px;
  position: relative;
  .write {
    position: absolute;
    right: 5px;
    color: #C3C6D6;
  }
  .close_icon {
    display: none;
    z-index: 2;
    position: absolute;
    right: 25px;
    color: #C3C6D6;
    cursor: pointer;
  }
  &:hover {
    .close_icon {
      display: inline-block;
    }
  }
}
.span_tag {
  color: #878A92;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  z-index: 0;
  top: 18px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.to_top {
  top: 0px;
  color: #878a92;
}
.input_tag {
  width: 100%;
  position: relative;
  top: 1px;
  color: #4B4F58;
  z-index: 1;
  border-bottom: 1px solid #C3C6D6;
  padding-right: 20px;
  ::v-deep {
    .el-input__inner {
      border: none;
      width: 100%;
      height: 22px;
      font-size: 12px;
      font-weight: bold;
      background: none;
      padding-left: 0;
    }
    .el-input__prefix {
      position: absolute;
      left: auto;
      top: auto;
      right: 5px;
      bottom: 0;
      height: 32px;
      line-height: 40px;
    }
    .el-input__suffix-inner {
      position: absolute;
      right: 20px;
      top: -10px;
    }
  }
}

</style>
